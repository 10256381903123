.landingPageBackground {
  background-image: url("../public/images/landingpage.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}

.exploreMoreButton {
  background-color: transparent;
  /* If button background is causing issues */
  color: white;
  border: 2px solid white;
  /* Or use a suitable border style */
}

.logo {
  max-height: 80px;
  /* Adjust the height as needed */
  width: auto;
}

.navbarBrandContainer {
  flex: 1;
  /* This will make the container take all available space */
  display: flex;
  justify-content: flex-start;
  /* Aligns the logo to the left */
}